exports.components = {
  "component---src-components-about-index-js": () => import("./../../../src/components/about/index.js" /* webpackChunkName: "component---src-components-about-index-js" */),
  "component---src-components-clients-index-js": () => import("./../../../src/components/clients/index.js" /* webpackChunkName: "component---src-components-clients-index-js" */),
  "component---src-components-comingsoon-index-js": () => import("./../../../src/components/comingsoon/index.js" /* webpackChunkName: "component---src-components-comingsoon-index-js" */),
  "component---src-components-contact-index-js": () => import("./../../../src/components/contact/index.js" /* webpackChunkName: "component---src-components-contact-index-js" */),
  "component---src-components-foodchain-index-js": () => import("./../../../src/components/foodchain/index.js" /* webpackChunkName: "component---src-components-foodchain-index-js" */),
  "component---src-components-news-index-js": () => import("./../../../src/components/news/index.js" /* webpackChunkName: "component---src-components-news-index-js" */),
  "component---src-components-product-index-js": () => import("./../../../src/components/product/index.js" /* webpackChunkName: "component---src-components-product-index-js" */),
  "component---src-components-product-order-js": () => import("./../../../src/components/product/order.js" /* webpackChunkName: "component---src-components-product-order-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

